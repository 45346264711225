import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingSpinner, ErrorFallback } from './components/common';
import './App.css';

// Lazy load components for better initial load performance
const Hero = React.lazy(() => import('./components/Hero'));
const StockMarketSection = React.lazy(() => import('./components/StockMarketSection'));
const AIAnalysisSection = React.lazy(() => import('./components/AIAnalysisSection'));
const MatrixSection = React.lazy(() => import('./components/MatrixSection'));
const InvestmentEcosystem = React.lazy(() => import('./components/InvestmentEcosystem'));
const IntelligenceHub = React.lazy(() => import('./components/IntelligenceHub'));
const InvestorAccessSection = React.lazy(() => import('./components/InvestorAccessSection'));

const App = () => {
  useEffect(() => {
    // Initialize any global services or analytics
    const initializeApp = async () => {
      try {
        // Add initialization logic here
        console.log('NikolaIQ platform initialized');
      } catch (error) {
        console.error('Initialization error:', error);
      }
    };

    initializeApp();
  }, []);

  // Component level error handler
  const handleError = (error, errorInfo) => {
    console.error('Application error:', error, errorInfo);
    // Add error reporting service integration here
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
    >
      <div className="App">
        <Helmet>
          <title>nikolaIQ | Next-Generation Quantum AI Investment Platform</title>
          <meta name="description" content="NikolaIQ combines quantum computing and AI to deliver institutional-grade investment strategies. Join the future of algorithmic trading." />
          <meta name="keywords" content="quantum computing, AI trading, hedge fund, algorithmic trading, institutional investment" />
          <meta property="og:title" content="NikolaIQ - Quantum AI Investment Platform" />
          <meta property="og:description" content="Experience the convergence of quantum computing and AI in institutional-grade investment strategies." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.nikolaiq.com" />
          <link rel="canonical" href="https://www.nikolaiq.com" />
        </Helmet>

        <main>
          <Suspense fallback={<LoadingSpinner />}>
            <Hero />
          </Suspense>

          <Suspense fallback={<LoadingSpinner />}>
            <StockMarketSection />
          </Suspense>

          <Suspense fallback={<LoadingSpinner />}>
            <AIAnalysisSection />
          </Suspense>

          <Suspense fallback={<LoadingSpinner />}>
            <MatrixSection />
          </Suspense>

          <Suspense fallback={<LoadingSpinner />}>
            <InvestmentEcosystem />
          </Suspense>

          <Suspense fallback={<LoadingSpinner />}>
            <IntelligenceHub />
          </Suspense>

          <Suspense fallback={<LoadingSpinner />}>
            <InvestorAccessSection />
          </Suspense>
        </main>

        {/* Add ScrollToTop component here if needed */}
        {/* Add Cookie Consent component here if needed */}
        {/* Add Chat Support component here if needed */}
      </div>
    </ErrorBoundary>
  );
};

export default App;
