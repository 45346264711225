import React from 'react';

export const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-[200px]">
    <div className="w-12 h-12 border-4 border-t-blue-500 border-blue-200 rounded-full animate-spin"></div>
  </div>
);

export const ErrorFallback = ({ error }) => (
  <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
    <h2 className="text-2xl font-bold text-red-500 mb-4">Something went wrong</h2>
    <p className="text-gray-300 mb-4">{error.message}</p>
    <button
      onClick={() => window.location.reload()}
      className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
    >
      Refresh Page
    </button>
  </div>
);